import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    mergeStyleSets,
    DefaultEffects,
    Stack,
    IStackTokens,
    Spinner,
    SpinnerSize,
} from '@fluentui/react';
import { PageTitle } from '../../../HubLayout/components/PageLayout/PageTitle';
import { PageContent } from '../../../HubLayout/components/PageLayout/PageContent';
import { useAsState } from '../../../Common/hooks/useAsState';
import { useParams } from 'react-router-dom';
import { ArtifactRouteParams } from '../../../Common/util/RouteUtils';
import { CancelToken } from 'axios';
import { usePromise } from '../../../Common/hooks/usePromise';
import { ErrorPage } from '../../../HubLayout/components/ErrorPage';
import { getArtifactDetails, getManifestDownload } from '../../api/AGSSApi';
import { ReplicationList } from './ReplicationList';
import { ArtifactMetrics } from './ArtifactMetrics';
import { ReplicateFullBuildButton } from './ReplicateFullBuildButton';
import { ValidateArtifactButton } from './ValidateArtifactButton';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { Replication } from '../../models/Replication';
import { TimelineItem } from '../../models/Timeline';
import {
    getCurrentReplicationTimeline,
    getReleaseDetailsPath,
} from '../../util/ReleaseUtils';
import { dateLocaleStringWithTimeZone } from '../../../Common/util/DateUtils';
import { CurrentReplicationTimeline } from '../MultistageReleaseData/Display/Status/CurrentReplicationTimeline';
import {
    sectionStyle,
    spinnerStyles,
    stackStyles,
} from '../../util/StyleUtils';
import { InternalLink } from '../../../Common/components/Links';
import { CompletionStatusDisplay } from '../MultistageReleaseData/Display/Status/CompletionStatusDisplay';
import { emitPageLoadMetric } from '../../../Common/util/metricsUtil';
import { BackButton } from '../../../HubLayout/components/Utilities/BackButton';
import { DownloadManifestButton } from './DownloadManifestButton';
import { MobileArtifactsDetailsPage } from './Mobile/MobileArtifactsDetailsPage';
import { useIsMobile } from '../../../Common/hooks/useIsMobile';

export const ArtifactDetailsPage: React.FC = () => {
    const routeParams = useParams<ArtifactRouteParams>();
    const params = useAsState<ArtifactRouteParams>(routeParams); // fixes timing bug on routeParam change
    const [replications, setReplications] = useState<Replication[]>([]);
    const [currentFlightTimeline, setCurrentFlightTimeline] =
        useState<TimelineItem[]>();
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const isMobile = useIsMobile();

    const getData = useCallback(
        async (cancelToken?: CancelToken) => {
            if (params.artifactId) {
                const artifactsResult = await getArtifactDetails(
                    params.artifactId,
                    cancelToken
                );
                return artifactsResult;
            } else {
                throw new Error('Invalid artifact ID provided.');
            }
        },
        [params.artifactId]
    );

    // Primary call to get the artifact data
    const [data, error, isLoaded] = usePromise(getData, true);

    useEffect(() => {
        setReplications(data?.replications || []);
        setCurrentFlightTimeline(
            getCurrentReplicationTimeline(data?.currentReplication)
        );
    }, [data]);

    const addReplication = async () => {
        // let's just get all the replications from the server
        var d = await getData();
        setReplications(d?.replications || []);
    };

    const styles = mergeStyleSets({
        metadataPanel: {
            backgroundColor:
                themeContext.themeName === 'dark'
                    ? theme.palette.neutralLighter
                    : theme.palette.themeLighterAlt,
            boxShadow: DefaultEffects.elevation4,
            padding: 5,
            marginBottom: 10,
            color: theme.palette.neutralSecondary,
            selectors: {
                table: {
                    width: '100%',
                },
                td: {
                    padding: 4,
                },
            },
        },
        label: {
            width: 120,
            fontWeight: 600,
            color: theme.palette.neutralPrimary,
        },
    });

    const stackTokens: IStackTokens = { childrenGap: 10 };
    const sectionStackTokens: IStackTokens = { childrenGap: 20 };

    const metadata = (
        label: string,
        value: any,
        isLink?: boolean
    ): JSX.Element => (
        <>
            <td className={styles.label}> {label}: </td>
            {!isLink && <td> {value} </td>}
            {isLink && (value as string[]) && (
                <td>
                    <InternalLink
                        value={value[0] ? value[0] : value[1]}
                        url={
                            '#' +
                            getReleaseDetailsPath(
                                value[1]
                                //item.serviceTreeId || '',
                                //props.serviceType
                            )
                        }
                        title="View release details"
                    />
                </td>
            )}
        </>
    );

    const buttons = (
        <Stack horizontal tokens={stackTokens}>
            <ValidateArtifactButton artifact={data} />
            <ReplicateFullBuildButton
                addReplication={addReplication}
                artifact={data}
            />
            <DownloadManifestButton artifact={data} />
        </Stack>
    );

    // metric to measure inital page load time
    useEffect(() => {
        var emittedSuccessfully = emitPageLoadMetric(
            'ArtifactDetails',
            process.env.REACT_APP_BUILD_VERSION,
            isFirstLoad
        );

        setIsFirstLoad(emittedSuccessfully);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return error ? (
        <ErrorPage error={error} />
    ) : (
        <div className="ArtifactDetailsPage-root">
            <PageTitle
                title={
                    'Hydration Manifest Details' +
                    (data?.artifactId
                        ? ' (Id: ' + data.artifactId + '.agss)'
                        : '')
                }
                subTitle="Hydration Manifest details, replications list, and status log"
            />
            {isLoaded &&
                (isMobile ? (
                    <MobileArtifactsDetailsPage
                        artifact={data}
                        addReplication={addReplication}
                    />
                ) : (
                    <Stack tokens={stackTokens}>
                        {data && data.currentReplication && (
                            <CompletionStatusDisplay
                                completionIndicator={data.completionIndicator}
                                completionStatusTracker={
                                    data.currentReplication
                                        .completionStatusTracker
                                }
                            />
                        )}
                        <Stack horizontal tokens={stackTokens}>
                            <BackButton />
                            {buttons}
                        </Stack>
                        <PageContent>
                            {data && (
                                <Stack tokens={sectionStackTokens}>
                                    <ArtifactMetrics data={data} />
                                    <div className={styles.metadataPanel}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    {metadata(
                                                        'Hydration Manifest Id',
                                                        data?.artifactId +
                                                            '.agss'
                                                    )}

                                                    {metadata(
                                                        'Release',
                                                        [
                                                            data.releaseName,
                                                            data.releaseCorrelationId,
                                                        ],
                                                        true
                                                    )}
                                                </tr>
                                                <tr>
                                                    {metadata(
                                                        'Build Number',
                                                        data?.buildNumber
                                                    )}
                                                    {metadata(
                                                        'Created On',
                                                        dateLocaleStringWithTimeZone(
                                                            data?.createdOn
                                                        )
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <Stack
                                        horizontal
                                        tokens={sectionStackTokens}
                                        className={stackStyles.paddedStack}
                                        style={sectionStyle(theme)}
                                    >
                                        <ReplicationList
                                            replications={replications}
                                            showTitle={true}
                                            isLoaded={isLoaded}
                                            setCurrentFlightTimeline={
                                                setCurrentFlightTimeline
                                            }
                                            backgroundColor={
                                                sectionStyle(theme)
                                                    .backgroundColor
                                            }
                                        />
                                        <CurrentReplicationTimeline
                                            currentFlightTimeline={
                                                currentFlightTimeline
                                            }
                                        />
                                    </Stack>
                                </Stack>
                            )}
                        </PageContent>
                    </Stack>
                ))}
            {!isLoaded && (
                <div>
                    <Spinner styles={spinnerStyles} size={SpinnerSize.large} />
                </div>
            )}
        </div>
    );
};
